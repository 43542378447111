<script>
export default {
  name: 'createBiblioListe',
  data () {
    return {
      selectedList: null
    }
  },
  props: {
    dialogListSwitch: { required: true, type: Boolean },
    selectedProduit: { type: Object, required: false, default: () => {} },
    currentSelectedList: { type: String, required: false, default: '' }
  },
  computed: {
    listesBiblio () {
      return this.$store.state.Bibliotheque.listesBiblio
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    reset () {
      this.listName = null
    },
    clearDialog () {
      this.listName = null
      this.$emit('closeDialog')
    },
    transferItem () {
      // this.showErrorPseudo = false
      this.$emit('switchElement', this.selectedList)
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-model='dialogListSwitch', max-width='600', content-class='custom-dialog', persistent)
  v-card.primary.lighten-1()
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='clearDialog', @click.native='reset', depressed, fab, x-large, color='light')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title Transfert de document
    v-card-text.dark--text
      div.dialog-text
        //- pre {{currentSelectedList}}
        //- pre {{selectedProduit}}
        div.b.f4.mb3 Choisi une liste vers laquelle transferer ton livre
        //- div.f6 Blablabla

    v-card-text.relative
      //- v-select.code-input(placeholder='Le nom de ta liste ici!', solo, hide-details, v-model='listName', clearable, @keyup.enter='createList')
      //- v-text-field.code-input(placeholder='Le nom de ta liste ici!', solo, hide-details, v-model='listName', clearable, @keyup.enter='createList')
      v-select(
        solo,
        depressed,
        v-model='selectedList',
        :items='listesBiblio',
        item-text='titre',
        item-value='titre',
        label='Transférer dans cette liste',
        persistent-hint,
        single-line
      )
    v-divider.primary
    v-btn(@click='transferItem', color='secondary', depressed, block, x-large, dark)
      span.f4 Envoyer
  //- v-card.primary.lighten-1(v-else-if='finish')
    v-card-text.dark--text
      div.b.f4.mb3 Demande envoyé
        v-divider.primary
    v-card-actions.primary
      v-spacer
      v-btn(@click='$emit("closeDialog")', @click.native='reset', outlined, color='secondary') Fermer
</template>
<style lang='sass'>

</style>
