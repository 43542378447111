<script>
import openLink from '@/mixins/openLink.js'
import DetailNoeudContenu from '@/components/bibliotheque/detailNoeudContenu'
import DetailGraphV2Contenu from '@/components/bibliotheque/detailGraphV2Contenu'

export default {
  name: 'GetDetailContenu',
  mixins: [openLink],
  props: {
    contenu: { required: true, type: Object },
    nuage: { required: false, type: Object, default: () => null },
    lecture: { required: false, type: Object, default: () => null },
    exercice: { required: false, type: Boolean, default: false },
    disabled: { required: false, type: Boolean, default: false },
    filtredMotsThematiques: { required: false, type: Object, default: () => null }
  },
  components: {
    DetailNoeudContenu,
    DetailGraphV2Contenu
  },
  data () {
    return {
      nodes: [],
      loading: true,
      noeudsContenu: [],
      noeudSource: null,
      onlyV2: false
    }
  },
  apollo: {
    noeudsContenu: {
      query: require('@/graphql/queries/v2/graphArbreAllNoeuds.gql'),
      variables () {
        let vars = []
        if (this.lecture) {
          vars = [
            {
              type: 'CONTENANT',
              data: { type: 'Gestionnaire V1' }
            }
          ]
        } else {
          vars = [
            {
              type: 'CONTENANT',
              data: { type: 'Gestionnaire V1' }
            },
            {
              type: 'CONTENANT',
              data: { type: 'Arbre V1' }
            }
          ]
        }
        return {
          id: this.contenu.id,
          contenus: vars
        }
      },
      update (data) {
        if (data.graphArbre && data.graphArbre.noeuds && data.graphArbre.noeuds.length > 0) {
          this.loading = false
          this.noeudSource = data.graphArbre.noeuds.filter(e => e.titre.toLowerCase().includes('sommaire'))
          data.graphArbre.noeuds.forEach(element => {
            const nombre = JSON.parse(element.contenu.data)
            this.$store.commit('Bibliotheque/addNombrePageDocument', nombre.mot_total + nombre.thematique_total)
          })
          if (!this.lecture) {
            return data.graphArbre.noeuds
          } else {
            return data.graphArbre.noeuds.filter((e) => {
              if (e.contenu && e.contenu.data) {
                const parseDat = JSON.parse(e.contenu.data)
                if (this.lecture.version === 1 && this.lecture.gestionnaires.find(e => e === parseDat.id)) {
                  return e
                } else if (this.lecture.version === 2) {
                  return this.lecture.contenu.find(c => c.gestionnaires.includes(parseDat.id))
                }
              }
            })
          }
        } else {
          this.onlyV2 = true
          this.loading = false
          return []
        }
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    goToContent () {
      if (this.exercice) {
        this.writeTokenInCookiesAndOpenLink('https://exercices.dokoma.com/exercice/' + this.nuage.boutiqueItem.slug)
      } else {
        this.writeTokenInCookiesAndOpenLink('https://glossaire.dokoma.com/v2/fr/document/' + this.nuage.boutiqueItem.slug + '/graph/' + this.contenu.slug)
      }
    }
  }
}
</script>

<template lang="pug">
  .get-detail-contenu.w-100
    template(v-if='!loading')
      v-expansion-panel.w-100(light)
        v-expansion-panel-header.b(:color='$store.state.Preferences.darkMode ? "light darken-2" : "var(--v-primary-lighten1)"', style='padding: 16px 24px 16px 24px')
          v-list-item-content(style='padding: 0')
            div(style='font-weight:normal;', v-html='contenu && contenu.noeud && contenu.noeud.contenu && contenu.noeud.contenu.titre ? contenu.noeud.contenu.titre : contenu.titre')
          v-list-item-action
        v-expansion-panel-content(style='padding:0;', :color='$store.state.Preferences.darkMode ? "light darken-1" : "light"')
          v-expansion-panels.flex-column
            template(v-if='onlyV2')
              detail-graph-v2-contenu(:contenu='contenu', :nuage='nuage', :disabled='disabled')
            template(v-else)
              template(v-for='(noeud, index) in noeudsContenu')
                detail-noeud-contenu(:noeud='noeud', :key='index', :lecture='lecture', :nuage='nuage', :arbre='contenu', :noeudSource='noeudSource && noeudSource.length > 0 ? noeudSource[0] : null', :exercice='exercice', :disabled='disabled', :filtredMotsThematiques='filtredMotsThematiques')
                v-divider(v-if='index < noeudsContenu.length - 1', :key='index + "divider"')
    template(v-else)
      template(v-for='(n, index) in 2')
        v-skeleton-loader(type='list-item')
        v-divider(v-if='index < 1')
</template>

<style lang='sass'>
  .v-expansion-panel-header__icon
    margin-right: 16px !important
</style>
