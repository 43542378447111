<script>
import { debounce } from 'lodash'
import ExerciceInformation from '@/components/exercices/exerciceInformations.vue'
import BoutiqueSup from '@/mixins/boutiqueSup.js'
import openLink from '@/mixins/openLink.js'

export default {
  name: 'DetailNoeudContenu',
  props: {
    noeud: { required: false, type: Object, default: () => {} },
    nuage: { required: false, type: Object, default: () => null },
    gestionnaire: { required: false, type: String, default: null },
    noeudSource: { required: false, type: Object, default: null },
    arbre: { required: false, type: Object, default: null },
    selectable: { required: false, type: Boolean, default: false },
    lecture: { required: false, type: Object, default: null },
    exercice: { required: false, type: Boolean, default: false },
    activeGraphExtrait: { required: false, type: Object, default: () => null },
    graph: { required: false, type: Object, default: () => null },
    disabled: { required: false, type: Boolean, default: false },
    filtredMotsThematiques: { required: false, type: Object, default: () => null }
  },
  data () {
    return {
      infoGestionnaire: null,
      mots: null,
      thematiques: null,
      page: 1,
      itemPerPage: 10,
      searchQMotThem: '',
      loadingSearchMot: true,
      loadingSearchThem: true,
      infoSup: null,
      options: {
        page: 1,
        limit: 10
      },
      headersResults: [
        { text: this.$t('partage.titre'), value: 'titre.current_locale', show: true, filterable: true },
        // { text: 'Type', value: '__typename', show: true },
        { text: this.$t('partage.actions'), value: 'actions', align: 'right' }
      ],
      search: null,
      hideMots: false,
      selectedPage: [],
      total: 0,
      motsById: null,
      thematiquesById: null,
      totalThem: 0
    }
  },
  mixins: [BoutiqueSup, openLink],
  components: {
    ExerciceInformation
  },
  watch: {
    selectedPage (newVal) {
      const selectedPageList = newVal?.map(e => {
        return { id: e.id, type: e.__typename === 'GlossaireMotBaseQuery' ? 'mot' : 'thematique', gestionnaire: this.gestionnaireId, noeud: this.activeGraphExtrait, graph: this.graph.slug }
      })
      if (selectedPageList?.length > 0) {
        this.$store.commit('Dialogs/addMultiplePage', selectedPageList)
      } else {
        this.$store.commit('Dialogs/deleteMultiplePage', this.gestionnaireId)
      }
    },
    options: {
      immediate: true,
      handler (newVal) {
        if (newVal.page) {
          this.page = newVal.page
        }
        if (newVal.itemsPerPage) {
          this.itemPerPage = newVal.itemsPerPage
        }
        this.fetchGlossaireMots()
        this.fetchGlossaireThematiques()
      }
    },
    searchQMotThem () {
      this.fetchGlossaireMots()
      this.fetchGlossaireThematiques()
    }
  },
  computed: {
    noeudTitre () {
      if (this.noeud) {
        if (this.noeud.contenu && this.noeud.contenu.__typename === 'GraphNoeudContenant') {
          if (this.infoGestionnaire && this.infoGestionnaire.sous_titre && this.infoGestionnaire.sous_titre.current_locale) {
            return this.infoGestionnaire.sous_titre.current_locale
          } else {
            if (JSON.parse(this.noeud.contenu.data).sous_titre) {
              return JSON.parse(this.noeud.contenu.data)?.sous_titre
            } else {
              return this.noeud.contenu.titre
            }
          }
        } else {
          return this.noeud.titre
        }
      } else {
        return ''
      }
    },
    totalMotThematique () {
      const motsCount = !this.hideMots && this.motsActifs ? this.motsActifs.total : 0
      const themCount = this.thematiquesActifs ? this.thematiquesActifs.total : 0
      return this.hideMots ? themCount : motsCount
    },
    gestionnaireId () {
      let temp = null
      if (this.noeud && this.noeud.contenu && this.noeud.contenu.__typename === 'GraphNoeudContenant' && this.noeud.contenu.data.includes('Gestionnaire')) {
        temp = JSON.parse(this.noeud.contenu.data).id
      } else if (this.gestionnaire) {
        temp = this.gestionnaire
      } else if (this.noeud && this.noeud.gestionnaire) {
        temp = this.noeud.gestionnaire
      }
      return temp
    },
    mothematique () {
      const thems = this.thematiquesActifs?.total > 0 ? this.thematiquesActifs.records : []
      const mots = this.motsActifs?.total > 0 ? this.motsActifs.records : []
      return this.hideMots ? thems : mots
    },
    motsActifs () {
      if (this.lecture) {
        if (this.motsListeLecture && this.motsListeLecture.total > 0) {
          if (this.searchQMotThem) {
            const listSearch = this.motsListeLecture.records.filter(e => e.titre.current_locale.toLowerCase().includes(this.searchQMotThem))
            return { total: listSearch.length, records: listSearch }
          } else {
            return this.motsListeLecture
          }
        } else {
          return { total: 0, records: [] }
        }
      } else {
        return this.mots
      }
    },
    thematiquesActifs () {
      if (this.lecture) {
        if (this.thematiquesListLecture && this.thematiquesListLecture.total > 0) {
          if (this.searchQMotThem) {
            const listSearch = this.thematiquesListLecture.records.filter(e => e.titre.current_locale.toLowerCase().includes(this.searchQMotThem))
            return { total: listSearch.length, records: listSearch }
          } else {
            return this.thematiquesListLecture
          }
        } else {
          return { total: 0, records: [] }
        }
      } else {
        return this.thematiques
      }
    },
    aliasMot () {
      let temp = null
      if (this.infoGestionnaire && this.infoGestionnaire.alias_mot && this.infoGestionnaire.alias_mot.singulier) {
        temp = this.infoGestionnaire.alias_mot.singulier
      }
      return temp
    },
    aliasThematique () {
      return this.infoGestionnaire?.alias_thematique?.singulier ?? this.$t('partage.thematique-case')
    },
    placeholder () {
      let temp = this.$t('action.find-mot')
      if (this.aliasMot || this.aliasThematique) {
        temp = this.$t('action.find-gen') + ' ' + this.aliasMot ? this.aliasMot : this.$t('partage.mot') + '/' + this.aliasThematique ? this.aliasThematique : this.$t('partage.thematique')
      }
      return temp
    },
    noDataText () {
      let temp = this.$t('partage.aucun-mot-thema')
      if ((this.infoGestionnaire && this.infoGestionnaire.alias_mot && this.infoGestionnaire.alias_mot.singulier) || (this.infoGestionnaire && this.infoGestionnaire.alias_thematique)) {
        temp = this.$t('partage.aucun') + ' ' + this.infoGestionnaire.alias_mot && this.infoGestionnaire.alias_mot.pluriel ? this.infoGestionnaire.alias_mot.pluriel : this.$t('partage.mots') + '/' + this.infoGestionnaire.alias_thematique && this.infoGestionnaire.alias_thematique.pluriel ? this.infoGestionnaire.alias_thematique.pluriel : this.$t('partage.thema-dispo')
      }
      return temp
    },
    idMotsLecture () {
      if (this.lecture) {
        if (this.lecture.version === 1) {
          return this.lecture.mots
        } else {
          // on va chercher le contenu qui nous interesse pour en retourner les mots
          const temp = this.lecture.contenu.filter(c => c.gestionnaires.includes(this.gestionnaireId))
          if (temp.length > 0) {
            return temp[0].mots
          } else {
            return []
          }
        }
      } else {
        return []
      }
    },
    idThematiquesLecture () {
      if (this.lecture) {
        if (this.lecture.version === 1) {
          return this.lecture.thematiques
        } else {
          // on va chercher le contenu qui nous interesse pour en retourner les mots
          const temp = this.lecture.contenu.filter(c => c.gestionnaires.includes(this.gestionnaireId))
          if (temp.length > 0) {
            return temp[0].thematiques
          } else {
            return []
          }
        }
      } else {
        return []
      }
    },
    idMotsLectureFiltred () {
      return this.idMotsLecture.filter(e => this.getMotsById?.records?.find(c => c.id === e))
    },
    idThematiquesLectureFiltred () {
      return this.idThematiquesLecture.filter(e => this.getThematiquesById?.records?.find(c => c.id === e))
    }

  },
  apollo: {
    motsListeLecture: {
      query: require('@/graphql/queries/plus/glossaireMots.gql'),
      client: 'plus',
      variables () {
        return {
          ids: this.idMotsLectureFiltred,
          limitMot: this.options.limit,
          pageMot: this.options.page
        }
      },
      update (data) {
        return { total: this.idMotsLectureFiltred.length, records: data.glossaire_mots }
      },
      skip () {
        return this.idMotsLectureFiltred.length === 0
      }
    },
    thematiquesListLecture: {
      query: require('@/graphql/queries/plus/glossaireThematiques.gql'),
      client: 'plus',
      variables () {
        return {
          ids: this.idThematiquesLecture,
          limitThematique: this.options.limit,
          pageThematique: this.options.page
        }
      },
      update (data) {
        return { total: this.idThematiquesLecture.length, records: data.glossaire_thematiques }
      },
      skip () {
        return this.idThematiquesLecture.length === 0
      }
    },
    getMotsById: {
      query: require('@/graphql/queries/plus/searchGlossaireMotsOpt.gql'),
      client: 'plus',
      variables () {
        return {
          gestionnaire_id: this.gestionnaireId,
          q: 'VIDE'
        }
      },
      update (data) {
        this.total = data.search_glossaire_mots.total
        this.motsById = data.search_glossaire_mots.records
        this.getMoreMotsById(1)
        return data.search_glossaire_mots
      },
      skip () {
        return !this.gestionnaireId && !this.lecture
      },
      fetchPolicy: 'network-only'
    },
    getThematiquesById: {
      query: require('@/graphql/queries/plus/searchGlossaireThematiquesOpt.gql'),
      client: 'plus',
      variables () {
        return {
          gestionnaire_ids: [this.gestionnaireId],
          q: this.searchQMotThem ? this.searchQMotThem : 'VIDE',
          page: 1
        }
      },
      update (data) {
        this.totalThem = data.search_glossaire_thematiques.total
        this.thematiquesById = data.search_glossaire_thematiques.records
        this.getMoreThematiquesById(1)
        return data.search_glossaire_thematiques
      },
      skip () {
        return !this.gestionnaireId && !this.lecture
      }
    }
  },
  methods: {
    async getMoreMotsById (page) {
      if (this.total > this.motsById.length) {
        const newPage = page + 1
        await this.$apollo.queries.getMotsById.fetchMore({
          variables: {
            page: newPage
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.search_glossaire_mots.records
            this.motsById.push(...newRes)
            this.getMoreMotsById(newPage)
          }
        })
      }
    },
    async getMoreThematiquesById (page) {
      if (this.totalThem > this.thematiquesById.length) {
        const newPage = page + 1
        await this.$apollo.queries.getMoreThematiquesById.fetchMore({
          variables: {
            page: newPage
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.search_glossaire_thematiques.records
            this.thematiquesById.push(...newRes)
            this.getMoreThematiquesById(newPage)
          }
        })
      }
    },
    async fetchGlossaireGestionnaire () {
      if (!this.gestionnaireId) {
        return null
      }
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/plus/glossaireGestionnaire.gql'),
        client: 'plus',
        variables: { id: this.gestionnaireId }
      })
      this.infoGestionnaire = data.glossaire_gestionnaire
      if (data.glossaire_gestionnaire.tag_invisibles?.find(t => t.name === 'afficher_uniquement_thematique')) {
        this.hideMots = true
      }
    },
    async fetchGlossaireMots () {
      if (this.hideMots || (!this.gestionnaireId)) {
        return null
      }

      this.loadingSearchMot = true
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/plus/searchGlossaireMots.gql'),
        client: 'plus',
        variables: {
          gestionnaire_id: this.gestionnaireId,
          limit: this.itemPerPage === -1 ? 100 : this.itemPerPage,
          q: this.searchQMotThem ? this.searchQMotThem : 'VIDE',
          page: this.page
        }
      })
      this.mots = data.search_glossaire_mots
      this.loadingSearchMot = false
    },
    async fetchGlossaireThematiques () {
      if (!this.gestionnaireId) {
        return null
      }
      this.loadingSearchThem = true
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/plus/searchGlossaireThematiques.gql'),
        client: 'plus',
        variables: {
          gestionnaire_ids: [this.gestionnaireId],
          limit: this.itemPerPage === -1 ? 100 : this.itemPerPage,
          page: this.page,
          q: this.searchQMotThem ? this.searchQMotThem : ''
        }
      })
      this.thematiques = data.search_glossaire_thematiques
      this.loadingSearchThem = false
    },
    handlePageSelect (item) {
      this.$store.commit('Dialogs/addPageSelect', { id: item.id, type: item.__typename === 'GlossaireMotBaseQuery' ? 'mot' : 'thematique', gestionnaire: this.gestionnaireId, noeud: this.activeGraphExtrait, graph: this.graph.slug })
    },
    updateSearch: debounce(function updateSearch () {
      this.searchQMotThem = this.search
    }, 500, { trailing: true }),
    getItem (item) {
      this.writeTokenInCookiesAndOpenLink(item)
    },
    async goToItem (item) {
      if (this.lecture || this.$route.name.includes('lectureEdition')) {
        if (this.selectable && !this.$route.query.id && !this.$route.query.document) {
          let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + this.$store.state.Dialogs.selectedCahier.boutiqueItem.slug
          temp += '/graph/' + (this.graph ? this.graph.slug : this.$store.state.Dialogs.activeGraphExtrait.slug)
          temp += '/node/' + (this.activeGraphExtrait ? this.activeGraphExtrait.slug : this.$store.state.Dialogs.noeudSourceExtrait)
          await this.$apollo.query({
            client: 'plus',
            query: require('@/graphql/queries/plus/glossaireGestionnaire.gql'),
            variables: { id: this.gestionnaireId }
          }).then(({ data }) => {
            if (data && data.glossaire_gestionnaire) {
              temp += '/glossaire/' + data.glossaire_gestionnaire.slug
            }
          })
          if (item.__typename === 'GlossaireMotBaseQuery') {
            temp += '/mot/' + item.slug
          } else {
            temp += '/thematique/' + item.slug
          }
          temp += '?readonly=true&dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
          this.writeTokenInCookiesAndOpenLink(temp)
        } else {
          let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + this.lecture.document
          temp += '/graph/' + this.arbre.slug + '/node/' + (this.lecture.noeudSource.slug ?? this.noeud.slug)
          const gestionnaire = item.gestionnaires.find(e => e.id === this.gestionnaireId.toString())
          temp += '/glossaire/' + gestionnaire.slug
          if (item.__typename === 'GlossaireMotBaseQuery') {
            temp += '/mot/' + item.slug
          } else {
            temp += '/thematique/' + item.slug
          }
          if (this.lecture) {
            temp += '?fiche=' + this.$route.query.document + '&dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
          } else {
            temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
          }
          this.writeTokenInCookiesAndOpenLink(temp)
        }
      } else {
        const idVar = this.$route.query.id || this.$route.query.document
        let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + idVar
        temp += '/graph/' + this.arbre.slug + '/node/' + this.noeud.slug
        const gestionnaire = item.gestionnaires.find(e => e.id === this.gestionnaireId)
        temp += '/glossaire/' + gestionnaire.slug
        if (item.__typename === 'GlossaireMotBaseQuery') {
          temp += '/mot/' + item.slug
        } else {
          temp += '/thematique/' + item.slug
        }
        temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
        this.writeTokenInCookiesAndOpenLink(temp)
      }
    },
    async goToContent () {
      if (this.exercice) {
        let temp = 'https://exercices.dokoma.com/exercice/' + this.$route.query.document + '/graph/' + this.arbre.slug
        this.writeTokenInCookiesAndOpenLink(temp)
      } else if (this.selectable) {
        let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + this.$store.state.Dialogs.selectedCahier.boutiqueItem.slug
        temp += '/graph/' + (this.activeGraphExtrait ? this.activeGraphExtrait.slug : this.$store.state.Dialogs.activeGraphExtrait.slug)
        temp += '/node/' + this.$store.state.Dialogs.noeudSourceExtrait
        await this.$apollo.query({
          client: 'plus',
          query: require('@/graphql/queries/plus/glossaireGestionnaire.gql'),
          variables: { id: this.gestionnaireId }
        }).then(({ data }) => {
          if (data && data.glossaire_gestionnaire) {
            temp += '/glossaire/' + data.glossaire_gestionnaire.slug
          }
        })
        this.writeTokenInCookiesAndOpenLink(temp)
      } else if (this.lecture) {
        let temp = 'https://glossaire.dokoma.com/v2/fr/document/' + this.lecture.document + '/graph/' + this.lecture.graph + '/node/' + this.lecture.noeudSource.slug
        const gestionnaire = await this.$apollo.query({
          query: require('@/graphql/queries/plus/glossaireGestionnaire.gql'),
          client: 'plus',
          variables: { id: this.gestionnaireId }
        }).then(({ data }) => {
          return data.glossaire_gestionnaire
        })
        temp += '/glossaire/' + gestionnaire.slug
        if (this.lecture) {
          temp += '?fiche=' + this.$route.query.document
        }
        this.writeTokenInCookiesAndOpenLink(temp)
      } else {
        const idVar = this.$route.query.id || this.$route.query.document
        let temp = null
        if (this.noeud && this.noeud.contenu && this.noeud.contenu.__typename === 'GraphNoeudContenant') {
          // on a besoin du noeud de base du graph
          const gestionnaire = await this.$apollo.query({
            query: require('@/graphql/queries/plus/glossaireGestionnaire.gql'),
            client: 'plus',
            variables: { id: JSON.parse(this.noeud.contenu.data).id }
          }).then(({ data }) => {
            return data.glossaire_gestionnaire
          })
          temp = 'https://glossaire.dokoma.com/v2/fr/document/' + idVar + '/graph/' + this.arbre.slug + '/node/' + this.noeud.slug + '/glossaire/' + gestionnaire.slug
        } else {
          temp = 'https://glossaire.dokoma.com/v2/fr/document/' + idVar + '/graph/' + this.arbre.slug + '/node/' + this.noeud.slug
        }
        this.writeTokenInCookiesAndOpenLink(temp)
      }
    }
  },
  async created () {
    await this.fetchGlossaireGestionnaire()
    this.fetchGlossaireMots()
    this.fetchGlossaireThematiques()
  }
}
</script>

<template lang="pug">
.detail-noeud-contenu.relative
  template(v-if='noeud')
    v-expansion-panel.w-100(light, @click.prevent)
      v-expansion-panel-header.b(@click='boutiqueItemSup(arbre.id)', :color='$store.state.Preferences.darkMode ? "light" : "white"', style='padding: 0')
        v-list-item(:key='"active-" + noeud.id')
          v-list-item-content
            div(style='font-weight:normal;', v-html='noeudTitre')
          v-list-item-action
            //- v-btn(:color='$store.state.Preferences.darkMode ? "secondary" : "primary"', rounded, x-small, dark, @click.stop='goToContent', outlined, :disabled='disabled')
            //-   span {{$t('action.consulter')}}
      v-expansion-panel-content(v-if='gestionnaireId', style='padding:0;', :color='$store.state.Preferences.darkMode ? "light" : "white"')
        div.pa3
          v-text-field(v-model='search', append-icon='mdi-magnify', :label='placeholder', rounded, outlined, dense, hide-details, background-color='white', @input='updateSearch')
        v-data-table(
          dense
          :headers='headersResults',
          item-key='id',
          :loading='loadingSearchThem',
          :items='mothematique',
          :items-per-page.sync='options.limit',
          :server-items-length='totalMotThematique',
          :footer-props='{ "items-per-page-options": [5, 10, 25, 50, 100] }',
          :options.sync='options',
          :search='search',
          :mobile-breakpoint='0',
          :hide-default-footer='totalMotThematique < itemPerPage',
          :no-data-text='search ? $t("alerte.aucun-result-recherche", { search }) : $t("alerte.aucun-result-afficher")'
        )
          template(v-slot:item.titre.current_locale='{ item }')
            div.d-flex.align-center
              v-chip.mr-2(v-if="item.__typename === 'GlossaireThematiqueBaseQuery'" small color="primary") {{ aliasThematique }}
              span {{ item.titre.current_locale }}
          template(v-slot:item.__typename='{ item }')
            div {{item.__typename === 'GlossaireMotBaseQuery' ? (aliasMot ? aliasMot : $t('partage.mot')) : (aliasThematique ? aliasThematique : $t('partage.thematique'))}}
          template(v-slot:item.actions='{ item }')
            div.flex.items-center.justify-end
              v-checkbox(v-if='selectable', hide-details, style='margin:5px 0;', @change='handlePageSelect(item)', :input-value='$store.state.Dialogs.pageSelect.find(e => e.id === item.id) ? true : false')
              v-btn.ml2(v-if='!selectable && $route.name.includes("bibliotheque") && item.document && item.document.full_url', icon, small, :download='item.titre && item.titre.current_locale ? item.titre.current_locale + (item.gestionnaires[0] && item.gestionnaires[0].titre && item.gestionnaires[0].titre.current_locale ? item.gestionnaires[0].titre.current_locale : "") : item.titre', :href='item.document.full_url', target='_blank', v-tooltip.bottom="{ content: $t('action.telecharger') , offset:'5px'}", color='secondary', fab, :disabled='disabled')
                font-awesome-icon.f4(:icon="['fad', 'file-download']")
              v-btn.ml2(icon, small, @click='goToItem(item)', v-tooltip.bottom="{ content: $t('partage.apercu') , offset:'5px'}", :disabled='disabled')
                font-awesome-icon.f4(:icon="['fad', 'eye']")
      v-expansion-panel-content(v-else-if='infoSup ')
        exercice-information(:infoSup='infoSup')
  template(v-else)
    div(v-if='gestionnaireId', @click.prevent)
      div.pa3
        v-text-field(v-model='search', append-icon='mdi-magnify', :label='placeholder', rounded, outlined, dense, hide-details, background-color='white', @input='updateSearch')
      v-data-table(
        dense
        :headers='headersResults',
        item-key='id',
        :loading='loadingSearchThem',
        :items='mothematique',
        v-model='selectedPage'
        :show-select= 'selectable',
        :items-per-page.sync='options.limit',
        :server-items-length='totalMotThematique',
        :footer-props='{ "items-per-page-options": [5, 10, 25, 50, 100] }',
        :options.sync='options',
        :search='search',
        :mobile-breakpoint='0',
        :hide-default-footer='totalMotThematique < itemPerPage',
        :no-data-text='search ? $t("alerte.aucun-result-recherche", { search }) : $t("alerte.aucun-result-afficher")',
      )
        template(v-slot:item.__typename='{ item }')
          div {{item.__typename === 'GlossaireMotBaseQuery' ? (aliasMot ? aliasMot : $t('partage.mot')) : (aliasThematique ? aliasThematique : $t('partage.thematique'))}}
        template(v-slot:item.actions='{ item }')
          div.flex.items-center.justify-end
            v-btn.ml2(v-if='!selectable && $route.name.includes("bibliotheque") && item.document && item.document.full_url', icon, medium, :download='item.titre && item.titre.current_locale ? item.titre.current_locale + (item.gestionnaires[0] && item.gestionnaires[0].titre && item.gestionnaires[0].titre.current_locale ? item.gestionnaires[0].titre.current_locale : "") : item.titre', :href='item.document.full_url', target='_blank', v-tooltip.bottom="{ content: $t('action.telecharger') , offset:'5px'}", :disabled='disabled')
              font-awesome-icon.f4(:icon="['fad', 'file-download']")
            v-btn.ml2(icon, small, @click='goToItem(item)', v-tooltip.bottom="{ content: 'Aperçu' , offset:'5px'}", :disabled='disabled')
              font-awesome-icon.f4(:icon="['fad', 'eye']")
</template>

<style lang='sass'>
.v-application p
  margin-bottom: 0px !important
.detail-noeud-contenu
  .v-data-table
    .v-data-table__wrapper > table > thead > tr > th
      padding: 5px 16px !important
  .v-expansion-panel
    .v-expansion-panel-content
      .v-expansion-panel-content__wrap
        padding: 0
        .v-data-table
          background-color: transparent !important
          tr
            height: 4rem !important
            margin: 1rem 0 m !important
            &:hover
              background-color: rgba(0,0,0,0.05) !important
</style>
