<script>
import openLink from '@/mixins/openLink.js'
import DetailNoeudContenu from '@/components/bibliotheque/detailNoeudContenu'

export default {
  name: 'DetailGraphV2Contenu',
  mixins: [openLink],
  props: {
    contenu: { required: true, type: Object },
    nuage: { required: false, type: Object, default: () => null },
    disabled: { required: false, type: Boolean, default: false }
  },
  components: {
    DetailNoeudContenu
  },
  data () {
    return {
      nodes: [],
      noeudsContenu: [],
      noeudSource: null,
      loading: true
    }
  },
  apollo: {
    noeudsContenu: {
      query: require('@/graphql/queries/v2/graphArbreAllNoeuds.gql'),
      variables () {
        return {
          id: this.contenu.id,
          contenus: [
            { type: 'QUESTION' },
            { type: 'RECUEIL' }
          ]
        }
      },
      update (data) {
        this.loading = false
        if (data.graphArbre && data.graphArbre.noeuds && data.graphArbre.noeuds.length > 0) {
          this.noeudSource = data.graphArbre.noeuds.filter(e => e.titre.toLowerCase().includes('sommaire'))
          return data.graphArbre.noeuds
        } else {
          return []
        }
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    goToContent (noeud) {
      const link = 'https://exercices.dokoma.com/exercice/' + this.nuage.boutiqueItem.slug + '/graph/' + this.contenu.slug + '?noeud=' + noeud.id
      this.writeTokenInCookiesAndOpenLink(link)
    }
  }
}
</script>

<template lang="pug">
.detail-graph-contenu
  div(v-if='loading')
    v-skeleton-loader(type='list-item')
  template(v-else)
    div(v-for='(noeud, index) in noeudsContenu')
      v-list-item
        v-list-item-content
          div(style='font-weight:normal;') {{noeud.titre}}
          //- div {{noeud}}
        v-list-item-action
          v-btn(:color='$store.state.Preferences.darkMode ? "secondary" : "primary"', rounded, x-small, dark, @click.stop='goToContent(noeud)', outlined, :disabled='disabled')
            span {{$t('action.consulter')}}
</template>

<style>
</style>
